<template lang="pug">
    .body-pdf.py-8
        .box-pdf.bg-white
            .p-8.pb-0.pt-0
                .box-pdf__header.flex.items-center.border-b.pb-4
                    .mr-8
                        //- img.mb-5(:src="detail.photo ? detail.photo : ''" alt="alt" @error="getErrorImage" style="height: 100px; width: auto;")
                        img.mb-5(:src="detail.photo ? detail.photo : ''" alt="alt" @error="(getErrorImage($event,'profile'))" style="height: 100px; width: auto;")
                    .block.flex-grow
                        .font-bold(style="padding-bottom:0px") Ringkasan Data
                        h1.subheading {{ detail.name ? detail.name : '-' }}
            .box-pdf__section
                h2.text-xl.font-bold.mb-3 Data
                .grid.grid-cols-4.gap-6
                    .call-out
                        span No. KTP
                        p {{ detail.ktp_number && detail.ktp_number.length > 0 ? detail.ktp_number : '-' }}
                    .call-out
                        span Provinsi
                        p {{ detail.province && detail.province.length > 0 ? detail.province : '-' }}
                    .call-out
                        span Alamat
                        p {{ detail.address && detail.address.length > 0 ? detail.address : '-' }}
                    .call-out
                        span Kota
                        p {{ detail.city && detail.city.length > 0 ? detail.city : '-' }}
                    .call-out
                        span Kecamatan
                        p {{ detail.district && detail.district.length > 0 ? detail.district : '-' }}
                    .call-out
                        span Kelurahan
                        p {{ detail.village && detail.village.length > 0 ? detail.village : '-' }}
                    .call-out
                        span RT/RW
                        p {{ detail.rt_rw && detail.rt_rw.length > 0 ? detail.rt_rw : '-' }}
                    .call-out
                        span Kode Pos
                        p {{ detail.postal_code && detail.postal_code.length > 0 ? detail.postal_code : '-' }}
                    .call-out
                        span Tanggal Lahir
                        //- p {{ detail.birth_date && detail.birth_date.length > 0 ? detail.birth_date : '-' }}
                        p {{ detail.birth_date_str && detail.birth_date_str.length > 0 ? detail.birth_date_str : '-' }}
                    .call-out
                        span Tempat Lahir
                        p {{ detail.birth_place && detail.birth_place.length > 0 ? detail.birth_place : '-' }}
                    .call-out
                        span Agama
                        p {{ detail.religion && detail.religion.length > 0 ? detail.religion : '-' }}
                    .call-out
                        span Pekerjaan
                        p {{ detail.profession && detail.profession.length > 0 ? detail.profession : '-' }}
                    .call-out
                        span Status Menikah
                        p {{ detail.marital_status && detail.marital_status.length > 0 ? detail.marital_status : '-' }}
                    .call-out
                        span Jenis Kelamin
                        p {{ detail.gender && detail.gender.length > 0 ? detail.gender : '-' }}
                    .call-out
                        span Gol. Darah
                        p {{ detail.blood_type && detail.blood_type.length > 0 ? detail.blood_type : '-' }}
                    .call-out
                        span Nomor KK
                        p {{ detail.kk_number && detail.kk_number.length > 0 ? detail.kk_number : '-' }}
                    .call-out
                        span SIM
                        template(v-if="detail.driving_license && detail.driving_license.length > 0")
                            p(v-for="item in detail.driving_license") {{ item.license_type }} - {{ item.license_number }}
                        p(v-else) -
                    .call-out
                        span STNK
                        template(v-if="detail.vehicle_reg && detail.vehicle_reg.length > 0")
                            p(v-for="item in detail.vehicle_reg") {{ item.type }} : {{ item.stnk_number }}
                        p(v-else) -
                        //- p Motor : 567567567
                        //- p Mobil : 123123123

                    .call-out
                        span Nomor Polisi
                        template(v-if="detail.vehicle_reg && detail.vehicle_reg.length > 0")
                            p(v-for="item in detail.vehicle_reg") {{ item.type }} : {{ item.police_reg_number }}
                        p(v-else) -
                        //- p Motor : B 1243 Y
                        //- p Mobil : B 1242 Y

                    .call-out
                        span MSISDN
                        //- p {{ detail.msisdn && detail.msisdn.length > 0 ? detail.msisdn.join(', ') : '-' }}
                        template(v-if="detail.msisdn && detail.msisdn.length > 0")
                            p(v-for="(item,index) in detail.msisdn" :key="index") {{ item }}
                        p(v-else) -
                        //- p Call history
                        //- p Location
                        //- p History location

            .box-pdf__section
                h2.text-xl.font-bold.mb-3 Profil

                h3.font-bold.mb-2.font-16 Profil Ringkas
                p.text-paragraph(v-html="detail.profile && detail.profile.length > 0 ? detail.profile : '-'")

                h3.font-bold.mb-2.font-16 Biografi Singkat
                p.text-paragraph(v-html="detail.biographic && detail.biographic.length > 0 ? detail.biographic : '-'")

                h3.font-bold.mb-2.font-16 Sosial Media
                .mb-5
                    table.w-full.border(v-if="detail.social_media && detail.social_media.length > 0")
                        thead
                            tr(valign="middle")
                                th.p-2.text-left.border-b.border-r(style="vertical-align: center") Sosial Media
                                th.p-2.text-left.border-b(style="vertical-align: center") Username
                        tbody
                            tr(v-for="item in detail.social_media" valign="center")
                                td.p-2.border-b.border-r(style="vertical-align: center") {{ item.media_type }}
                                td.p-2.border-b(style="vertical-align: center") {{ item.username && item.username.length > 0 ? item.username : '-' }}

                    span(v-else) Sosial media tidak tersedia

                h3.font-bold.mb-2.font-16 Pendidikan
                p.text-paragraph(v-html="detail.education && detail.education.length > 0 ? detail.education : '-'")

                h3.font-bold.mb-2.font-16 Perjalanan Karir
                p.text-paragraph(v-html="detail.career && detail.career.length > 0 ? detail.career : '-'")

            .box-pdf__section
                h2.text-xl.font-bold.mb-3 Karakter
                p.text-paragraph(v-html="detail.character && detail.character.length > 0 ? detail.character : '-'")

            .box-pdf__section
                h2.text-xl.font-bold.mb-3 Ambisi
                p.text-paragraph(v-html="detail.ambition && detail.ambition.length > 0 ? detail.ambition : '-'")

            .box-pdf__section
                h2.text-xl.font-bold.mb-3 Opini
                p.text-paragraph(v-html="detail.opinion && detail.opinion.length > 0 ? detail.opinion : '-'")

            .box-pdf__section
                h2.text-xl.font-bold.mb-3 Kekuatan
                p.text-paragraph(v-html="detail.strength && detail.strength.length > 0 ? detail.strength : '-'")

            .box-pdf__section
                h2.text-xl.font-bold.mb-3 Keluarga
                p.text-paragraph(v-html="detail.family && detail.family.length > 0 ? detail.family : '-'")

            .box-pdf__section
                h2.text-xl.font-bold.mb-3 Motif Agenda
                Chart(v-if="motive_agenda && Object.keys(motive_agenda).length > 0 && start_chart" :options="chartOptions").main-chart
                span(v-else) Motif agenda tidak tersedia

            .box-pdf__section
                //- h2.text-xl.font-bold.mb-3 Diexport
                .mb-5
                    table.w-full
                        tbody
                            tr(valign="center")
                                td.p-2(style="vertical-align: center; width: 113px;") Diexport oleh
                                td.p-2(style="vertical-align: center; width: 10px;") :
                                td.p-2(style="vertical-align: center") {{ profile.name || '-' }}
                            tr(valign="center")
                                td.p-2(style="vertical-align: center") Tanggal
                                td.p-2(style="vertical-align: center;") :
                                td.p-2(style="vertical-align: center") {{ getDate() }}


</template>

<script>
import {mapState} from 'vuex';
import CardInfo from '@/components/sections/Card';
import {Chart} from 'highcharts-vue';
import moment from 'moment'

export default {
    components: {
        CardInfo,
        Chart,
    },
    data() {
        return {
            facebook: '<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Logo Facebook</title><path d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z" fill-rule="evenodd" fill="white"/></svg>',
            instagram: '<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Logo Instagram</title><path fill="white" d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"/><path fill="white" d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z"/></svg>',
            twitter: '<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Logo Twitter</title><path fill="white" d="M496 109.5a201.8 201.8 0 01-56.55 15.3 97.51 97.51 0 0043.33-53.6 197.74 197.74 0 01-62.56 23.5A99.14 99.14 0 00348.31 64c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 002.54 22.1 280.7 280.7 0 01-203-101.3A95.69 95.69 0 0036 130.4c0 33.6 17.53 63.3 44 80.7A97.5 97.5 0 0135.22 199v1.2c0 47 34 86.1 79 95a100.76 100.76 0 01-25.94 3.4 94.38 94.38 0 01-18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3A199.59 199.59 0 0139.5 405.6a203 203 0 01-23.5-1.4A278.68 278.68 0 00166.74 448c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5A198.48 198.48 0 00496 109.5z"/></svg>',
            youtube: '<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Logo Youtube</title><path d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z"/></svg>',
            tiktok: '<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Logo Tiktok</title><path d="M412.19 118.66a109.27 109.27 0 01-9.45-5.5 132.87 132.87 0 01-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69 0 .52-.05 1-.08 1.56 0 .23 0 .47-.05.71v.18a70 70 0 01-35.22 55.56 68.8 68.8 0 01-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0121.41 3.39l.1-83.94a153.14 153.14 0 00-118 34.52 161.79 161.79 0 00-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 00115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34 7.66-.31 33.32 0 62.46-13.81 32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0027.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61z"/></svg>',
            vimeo: '<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Logo Vimeo</title><path d="M476.9 114c-5-23.39-17.51-38.78-40.61-46.27s-64.92-4.5-94.12 16.79c-26.79 19.51-46.26 54.42-54 78.28a4 4 0 005.13 5c10.77-3.8 21.72-7.1 34-6.45 15 .8 24.51 12 24.91 25.29.3 9.79-.2 18.69-3.6 27.68-10.74 28.68-27.61 56.46-47.55 80.75a72.49 72.49 0 01-10 9.89c-10.21 8.29-18.81 6.1-25.41-5.2-5.4-9.29-9-18.88-12.2-29.08-12.4-39.67-16.81-80.84-23.81-121.52-3.3-19.48-7-39.77-18-56.86-11.6-17.79-28.61-24.58-50-22-14.7 1.8-36.91 17.49-47.81 26.39 0 0-56 46.87-81.82 71.35l21.2 27s17.91-12.49 27.51-18.29c5.7-3.39 12.4-4.09 17.2.2 4.51 3.9 9.61 9 12.31 14.1 5.7 10.69 11.2 21.88 14.7 33.37 13.2 44.27 25.51 88.64 37.81 133.22 6.3 22.78 13.9 44.17 28 63.55 19.31 26.59 39.61 32.68 70.92 21.49 25.41-9.09 46.61-26.18 66-43.87 33.11-30.18 59.12-65.36 85.52-101.14 20.41-27.67 37.31-55.67 51.41-86.95C478.5 179.74 484 147.26 476.9 114z"/></svg>',

            start_chart: false,
            chartOptions: {
                title: {
                    text: null,
                },
                legend: {
                    enabled: false,
                },
                chart: {
                    type: 'area',
                    polar: true,
                },
                xAxis: {
                    // categories: ['sosbud', 'hankam', 'ideologi', 'ekonomi', 'politik']
                    categories: [],
                },
                series: [{
                    type: 'area',
                    name: 'Area',
                    // data: [1.5, 3.3, 2.7, 4.1, 3]
                    data: [],
                }],
            },
        };
    },
    computed: {
        ...mapState('auth', ['profile']),
        ...mapState('actor', [
            'status_detail',
            'detail',
            'motive_agenda',
        ]),
    },
    watch: {
        status_detail(newVal, oldVal) {
            if (newVal !== 'loading' && oldVal === 'loading') {
                // get motive agenda
                this.$store.dispatch('actor/getMotiveAgenda');
            }
        },
        motive_agenda(newVal) {
            if (newVal && Object.keys(newVal).length > 0)
                this.startChart();
        },
    },
    methods: {
        startChart() {
            let data_chart = this.motive_agenda;
            delete data_chart.average;

            let categories = [];
            let series = [];

            for (const index in data_chart) {
                categories.push(index);
                series.push(data_chart[index]);
            }

            this.chartOptions.xAxis.categories = categories;
            this.chartOptions.series[0].data = series;

            this.start_chart = true;
        },
        getDate() {
            return moment().locale('id').format('DD MMMM YYYY');
        },
    },
    mounted() {
        // console.log(this.profile);
    },
    // filters: {
    //     date: function(date) {
    //         return moment(date).locale('id').format('DD MMM YYYY');
    //     },
    // },
}
</script>

<style lang="sass" scoped>
    .content-header
        background-color: var(--bg-card)
        @apply flex p-4 rounded mb-2
        &__photo
            @apply mr-4
            img
                width: 100%
                max-width: 125px
                min-width: 125px
                height: 155px
                object-fit: cover
                @apply rounded
        &__info
            @apply w-full
            h1
                font-size: 24px
                font-weight: 600
                line-height: 28px
                margin-block: 0px
                margin-bottom: 15px
    .call-out
        span
            opacity: 0.6
            font-size: 12px
        p
            font-size: 14px

    .box-pdf__section
        border-bottom: 8px solid
        @apply p-8 border-gray-100

        .font-16
            font-size: 16px

    .card-info-pdf
        @apply pb-6 border-b
        background-color: transparent !important
        padding-top: 0px !important
        padding-left: 0px !important
        padding-right: 0px !important
        box-shadow: none !important
        .card__info
            .card-info
                p
                    font-size: 11px !important
                    font-weight: normal !important
</style>